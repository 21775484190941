@import "_vars";


label {
    display: inline-block;
    margin-bottom: 6px;
    font-size: 1.3rem;
    color: rgba($takumi, 0.7);
}

label + .tooltip {
    margin-left: 10px;
    cursor: help;
}

form {
    .input-container {
        margin-bottom: 20px;

        &-inline {
            & > * {
                display: inline-block;
                margin-right: 25px;
            }
        }
        &-block {
            & > * {
                display: block;
            }
        }

        &_error {
            color: $accentRed1;
        }
    }

    fieldset {
        border: none;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="search"],
    input[type="date"],
    textarea,
    select {
        width: 100%;
        padding: 12px 10px;
        border: 0;
        border: 1px $takumiLighter solid;
        font-family: inherit;
        font-size: 1.3rem;
        transition: all 0.3s;
        outline: none;
        color: $takumi;
        background: transparent;

        &::placeholder {
            color: $takumiLighter;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }
    textarea {
        min-width: 100%;
        max-width: 100%;
    }

    input[type="file"] {
        width: 100%;
    }
    .attachment-file {
        margin-bottom: 20px;

        button {
            margin-left: 25px;
        }
    }

    .checkbox-input {
        display: inline-block;
        height: $checkboxSize;
        margin-top: -7.5px;
        margin-right: 17px;
        margin-bottom: 14px;

        input {
            top: 7.5px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .repeater {
        &-container {
            display: flex;
            align-items: center;

            .input-container {
                flex-grow: 1;
                margin-right: 10px;
            }
        }
        &-remove {
                margin-top: -7px;
            cursor: pointer;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        position: relative;
        display: inline-block;
        z-index: 1;
        appearance: none;
        width: $checkboxSize;
        height: $checkboxSize;
        margin-right: 10px;
        border: 1px solid $takumiLighter;
        outline: none;

        &:hover, &:active, &:focus {
            border-color: $takumiLight;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40%;
            height: 40%;
            background: $mainColor;
            transform: translate(-50%, -50%) rotate(-45deg) scale(0);
            transform-origin: 50% 50%;
            transition: 0.1s all;
        }

        &:checked {
            &:after {
                transform: translate(-50%, -50%) rotate(-45deg) scale(1);
            }
        }
    }

    select {
        -webkit-appearance: none;
    }

    .fullwidth {
        width: 100%;
    }

    .autocomplete {
        position: relative;

        &-suggestions {
            position: absolute;
            width: 100%;
            padding: 10px;
            background: $white;
            border: 1px solid $takumiLighter;
            list-style: none;

            &-item {
                padding: 10px;

                &.active {
                    font-weight: bold;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.button {
    padding: 12px 20px;
    border: none;
    color: $takumi;
    font-family: inherit;
    background: transparent;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
        color: $white;
        background: $takumi;
    }

    &.primary, &[type="submit"]:not(.opposite) {
        color: $white;
        background: $secondaryColor;

        &:hover {
            color: $secondaryColor;
            background: transparent;
        }
    }

    &.opposite {
        color: $white;
        background: $takumi;

        &:hover {
            color: $takumi;
            background: transparent;
        }
    }
    &-alert {
        margin-bottom: 20px;

        &-right,
        &-right-up {
            float: right;
            &:after {
                content: '';
                clear: both;
            }
        }
        &-right-up {
            margin-top: -50px;
        }
    }
}
