@font-face {
    font-family: SuzukiPRORegular;
    src: url('/assets/fonts/SuzukiPRORegular.eot');
    src: url('/assets/fonts/SuzukiPRORegular.eot?#iefix') format("embedded-opentype"),
        url('/assets/fonts/SuzukiPRORegular.woff2') format("woff2"),
        url('/assets/fonts/SuzukiPRORegular.woff') format("woff"),
        url('/assets/fonts/SuzukiPRORegular.ttf') format("truetype"),
        url('/assets/fonts/SuzukiPRORegular.svg#SuzukiPRORegular') format("svg");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: SuzukiPRORegular;
    src: url('/assets/fonts/SuzukiPROBold.eot');
    src: url('/assets/fonts/SuzukiPROBold.eot?#iefix') format("embedded-opentype"),
        url('/assets/fonts/SuzukiPROBold.woff2') format("woff2"),
        url('/assets/fonts/SuzukiPROBold.woff') format("woff"),
        url('/assets/fonts/SuzukiPROBold.ttf') format("truetype"),
        url('/assets/fonts/SuzukiPROBold.svg#SuzukiPROBold') format("svg");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: SuzukiPROHeadline;
    src: url('/assets/fonts/SuzukiPROHeadline.eot');
    src: url('/assets/fonts/SuzukiPROHeadline.eot?#iefix') format("embedded-opentype"),
        url('/assets/fonts/SuzukiPROHeadline.woff2') format("woff2"),
        url('/assets/fonts/SuzukiPROHeadline.ttf') format("truetype"),
        url('/assets/fonts/SuzukiPROHeadline.svg#SuzukiPROHeadline') format("svg");
    font-weight: 400;
    font-style: normal
}
