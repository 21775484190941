.add-icon-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    & > * {
        @extend .button, .button.primary;
        display: inline-block;

        & > .lnr {
            margin-right: 10px;
        }
    }

    span {
        margin-left:10px;
        margin-bottom:10px;
    }
}
