* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    font: 10px 'SuzukiPRORegular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    min-height: 100%;
    font-size: 1.4rem;
    background-color: rgb(237, 237, 237);
}

a, a img {
    text-decoration: none;
}
a {
    color: inherit;
}

h1, h2, h3, h4, h5, h6, .title {
    margin-bottom: 1em;
    font-family: 'SuzukiPROHeadline';

    &:empty {
        margin-bottom: 2.2em;
    }
}

h1 {
    text-transform: uppercase;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:not(:first-child) {
        margin-left: 20px;
    }

    &.from-right {
        direction: rtl;

        & > *:not(:first-child) {
            margin-left: 0;
            margin-right: 20px;
        }
    }
}

.main {
    max-width: 1600px;
    min-height: calc(100vh - #{$footerHeight});
    margin: 0 auto;
    padding: 120px 20px 40px;
}

.app-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
