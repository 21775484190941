.header {
    position: fixed;
    z-index: 1000;
    display: flex;
    align-items: center;
    width: 100%;
    height: $headerHeight;
    color: $white;
    font-family: 'SuzukiPRORegular';
    font-weight: 700;
    background: $takumi;
    box-shadow: $defaultShadow;

    &_logo {
        height: $headerHeight;
        margin-right: 30px;
        cursor: pointer;
    }


    &_menu {
        position: fixed;
        top: 0;
        left: -100%;
        z-index: 10000;
        width: calc(100% - 80px);
        height: 100%;
        padding: 25% 20px;
        background: $takumi url('../../images/header-logo.png') no-repeat 20px 20px;
        background-size: 50% auto;
        transition: 0.3s all;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 100%;
            width: 0;
            height: 100%;
            background: rgba($black, 0.7);
            transition: 0.3s all;
        }

        ul {
            list-style: none;

            li {
                padding: 15px;
                font-size: 1.6rem;
            }
        }

        &.open {
            left: 0;

            &:after {
                width: 100%;
            }
        }
    }

    &_burger {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 100000;
        width: 40px;
        height: 40px;
        font-size: 40px;
    }


    @media screen and (min-width: $breakpointDesktop) {
        &_menu {
            position: static;
            display: block;
            flex: auto;
            width: auto;
            height: auto;
            padding: 0;
            background: none;

            &:after {
                content: none;
            }

            & > ul {
                list-style: none;
                text-align: right;

                & > li {
                    position: relative;
                    display: inline-block;
                    margin: 0 30px;
                    padding: 30px 20px;
                    cursor: pointer;

                    &.active:after, &.parent:after, &:hover:after {
                        content: '';
                        position: absolute;
                        left: 20px;
                        bottom: 0;
                        width: calc(100% - 40px);
                        height: 6px;
                        background: $mainColor;
                    }
                }
            }

            .sublevel-1 {
                display: none;
                position: absolute;
                top: calc(100%);
                left: 50%;
                z-index: 1;
                min-width: 100%;
                text-align: left;
                border-radius: 0 0 4px 4px;
                box-shadow: $defaultShadow;
                transform: translateX(-50%);

                & > li {
                    padding: 0;
                }
            }

            li:hover > ul {
                display: block;
                list-style: none;
                padding: 15px 20px;
                color: $takumi;
                font-family: 'SuzukiProRegular';
                background: $white;

                & > li {
                    position: relative;
                    padding: 8px 0;

                    &:hover, &.active, &.parent {
                        &:before {
                            content: '';
                            position: absolute;
                            display: block;
                            left: -10px;
                            top: 50%;
                            width: 6px;
                            height: 6px;
                            transform: translateY(-50%);
                            background: $mainColor;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        &_burger {
            display: none;
        }
    }
}
