.footer {
    display: flex;
    align-items: center;
    height: $footerHeight;
    padding: 0 20px;
    color: $white;
    background: $takumi;

    &_wrapper {
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
    }
}
