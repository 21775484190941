#flash-message--root {
}

.flash-message {
    min-width: 200px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 4px;
    background: $white;
    box-shadow: $darkShadow;
    transition: 0.3s all;
    transform-origin: 100% 0;

    &-container {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 10000;
    }

    &--hide {
        opacity: 0;
        transform: scale(0);
    }
}
