$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$darkerGrey : rgb(100, 100, 100);
$darkGrey : rgb(190, 190, 190);
$grey : rgb(237, 237, 237);
$blueGrey: rgb(178, 193, 200);
$takumi: rgb(0, 49, 69);
$takumiLight: rgb(114, 138, 149);
$takumiLighter: rgb(185, 198, 204);

$mainColor: rgb(213, 43, 30);
$accentRed1: rgb(198, 12, 48);
$accentRed2: rgb(247, 64, 58);
$accentGreen1: rgb(52, 178, 51);
$accentOrange2: rgb(255, 161, 0);
$secondaryColor: rgb(0, 152, 219);
$secondaryLighter: rgb(94, 182, 228);

$defaultShadow: 0 0 7px rgba(0, 0, 0, 0.01);
$darkShadow: 0 0 7px rgba(0, 0, 0, 0.05);

$headerHeight: 80px;
$footerHeight: 40px;

$checkboxSize: 25px;

$breakpointDesktop: 1200px;
$breakpointTabletLandscape: 1024px;
