.info-block {
    padding: 20px;
    background: rgb(247, 247, 247);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    h1, h2, h3, h4 {
        margin-top: 0;
    }

    & + & {
        margin-top: 40px;
    }
}
