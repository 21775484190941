.grid {
    display: flex;
    justify-content: space-between;
    clear: both;

    &.align-baseline {
        align-items: baseline;
    }

    &.align-end {
        align-items: flex-end;
    }

    &.align-middle {
        align-items: center;
    }

    & > * {
        width: 100%;
        margin-right: 40px;

        &.align-baseline {
            align-self: baseline;
        }

        &.align-end {
            align-self: flex-end;
        }

        &.align-middle {
            align-self: center;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    & > .grid {
        @for $i from 1 through 11 {
            &-#{$i} {
                flex: $i
            }
        }
    }

    & {
        @for $i from 1 through 8 {
            &.has-#{$i}-columns {
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;

                & > * {
                    flex: initial;
                    width: calc(((100% - #{($i - 1) * 40}px) / #{$i}));
                    margin-right: 40px;

                    &:nth-child(#{$i}n) {
                        margin-right: 0;
                    }

                    @for $j from 1 through $i {
                        &:nth-child(#{$j}) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}
