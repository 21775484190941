@import '_vars';

.minimal {
    height: 100%;
    background: $takumi;

    &-container {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 400px;
        padding: 50px;
        background: $white;
        border-radius: 4px;
        box-shadow: $defaultShadow;
        transform: translate(-50%, -50%);

        p {
            margin-bottom: 20px;
        }
    }

    &-logo {
        margin-bottom: 30px;
    }
}
